import * as React from "react";
import { cn } from "@/helpers/classNames";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: "div" | "section" | "aside" | "header" | "article" | "nav" | "figure";
  bleed?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  tag = "div",
  bleed = false,
  children = undefined,
  className,
  ...props
}) => {
  const Wrapper = tag;

  return (
    <Wrapper
      className={cn("container", bleed ? "!px-0" : "", className)}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
