import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { locales } from "@/locales/locales";
import { slug } from "@/utils/slug";

type RawTranslation = {
  language: string | null;
  slug: {
    current: string | null;
  } | null;
};

export interface TranslationsContextProps {
  translations: Record<string, string>;
  setTranslations: (
    translations: Array<RawTranslation | null> | undefined,
  ) => void;
}

const translationsContextDefaults: {
  translations: Record<string, string>;
} = {
  translations: {},
};

const TranslationsContext = React.createContext<TranslationsContextProps>(
  translationsContextDefaults as TranslationsContextProps,
);

const TranslationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [translations, setTranslationsState] = useState(
    translationsContextDefaults.translations,
  );

  const setTranslations = useCallback(
    (translations: Array<RawTranslation | null> | undefined) => {
      if (!translations) {
        setTranslationsState({});
        return;
      }

      setTranslationsState(
        translations.reduce<Record<string, string>>((prev, current) => {
          if (!current || !("language" in current) || !current.language) {
            return prev;
          }

          return {
            ...prev,
            [current.language]: slug(current.slug, current.language),
          };
        }, {}),
      );
    },
    [setTranslationsState],
  );

  const value = useMemo(
    () => ({
      translations,
      setTranslations,
    }),
    [translations, setTranslations],
  );

  return (
    <TranslationsContext.Provider value={value}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsContext;

export { TranslationsProvider };

export const useTranslations = () => useContext(TranslationsContext);

export const useLocalTranslations = (
  translations:
    | Readonly<Array<RawTranslation | { value: RawTranslation | null } | null>>
    | string
    | undefined
    | null,
) => {
  const { setTranslations } = useTranslations();

  useEffect(() => {
    if (typeof translations === "string") {
      setTranslations(
        locales.map((locale) => ({
          language: locale.locale,
          slug: {
            current: translations.replace(/^\//, ""),
          },
        })),
      );
    } else {
      setTranslations(
        translations?.map((translation) => {
          if (translation && "value" in translation) {
            return translation.value ?? null;
          }

          return translation ?? null;
        }),
      );
    }

    return () => setTranslations(undefined);
  }, [setTranslations, translations]);
};
