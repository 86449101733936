import React from "react";
import { AnimatePresence, m as motion } from "framer-motion";
import Link from "@/components/link";
import { useTheme } from "@/contexts/theme";
import { cn } from "@/helpers/classNames";
import { useIsMedium } from "@/hooks/useMediaQuery";
import ArrowLeftIcon from "@/icons/arrow-left";
import ChevronRightIcon from "@/icons/chevron-right";

type MegaMenuNavigationItemProps = {
  title: LinkType["title"];
  links?: Array<LinkType>;
  url?: LinkType["url"];
};

type MegaMenuNavigationProps = {
  items: Array<MegaMenuNavigationItemProps>;
};

const MegaMenuNavigationItem: React.FC<
  MegaMenuNavigationItemProps & {
    index: number | string;
    activeIndex: number | string | null;
    onExpand: (index: number | string) => void;
    onCollapse: (index: number | string) => void;
  }
> = ({
  title,
  url = undefined,
  links = undefined,
  index,
  activeIndex,
  onExpand,
  onCollapse,
}) => {
  const { setMenuOpen } = useTheme();
  const isMedium = useIsMedium();

  if (!links && url) {
    return (
      <Link to={url} onClick={() => setMenuOpen(false)}>
        {title}
      </Link>
    );
  }

  const isActive = activeIndex === index;

  return (
    <>
      <button
        type="button"
        className="flex items-center gap-xs"
        onClick={() => (isActive ? onCollapse(index) : onExpand(index))}
        onPointerEnter={() => (isMedium ? onExpand(index) : undefined)}
        aria-haspopup="menu"
        id={`subnav-label-${index}`}
        aria-controls={`subnav-${index}`}
        aria-expanded={isActive}
      >
        <span>{title}</span>
        <ChevronRightIcon className="size-5" />
      </button>
      <motion.div
        className="absolute inset-0 flex flex-col justify-center text-lg-fluid left-1/2"
        animate={isActive ? "visible" : "hidden"}
        initial="hidden"
        variants={{
          hidden: {
            pointerEvents: "none",
            opacity: 0,
            x: 5,
            transitionEnd: { pointerEvents: "none" },
          },
          visible: { pointerEvents: "initial", opacity: 1, x: 0 },
        }}
        inert={!isActive ? "" : undefined}
        id={`subnav-${index}`}
        aria-labelledby={`subnav-label-${index}`}
      >
        <ul className="space-y-sm">
          {links?.map(({ title, url }) => (
            <li key={title}>
              <Link to={url} onClick={() => setMenuOpen(false)}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </motion.div>
    </>
  );
};

const MegaMenuNavigation: React.FC<MegaMenuNavigationProps> = ({ items }) => {
  const { menuOpen, activeSubmenu, setActiveSubmenu } = useTheme();

  return (
    <>
      <AnimatePresence>
        {activeSubmenu !== null && (
          <motion.button
            className="fixed flex items-center justify-center size-10 left-base top-lg md:hidden"
            type="button"
            initial={{ x: 5, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -5, opacity: 0 }}
            onClick={() => setActiveSubmenu(null)}
          >
            <ArrowLeftIcon className="size-5" />
          </motion.button>
        )}
      </AnimatePresence>

      <motion.ul
        className={cn(
          "relative transition duration-300 ease-in-out flex flex-col justify-center h-full w-[200%] space-y-3f sm:space-y-2f md:w-full",
          activeSubmenu !== null
            ? "-translate-x-1/2 md:translate-x-0"
            : undefined,
        )}
        initial="hidden"
        animate={menuOpen ? "visible" : "hidden"}
        variants={{
          hidden: {},
          visible: {
            transition: {
              delayChildren: 0.2,
              staggerChildren: 0.075,
            },
          },
        }}
      >
        {items.map((item, i) => (
          <motion.li
            key={item.title}
            variants={{
              hidden: { y: 20, opacity: 0 },
              visible: { y: 0, opacity: 1 },
            }}
            transition={{ ease: "easeOut" }}
            className="w-1/2 pr-gutter"
          >
            <MegaMenuNavigationItem
              index={i}
              activeIndex={activeSubmenu}
              onExpand={(index) => setActiveSubmenu(index)}
              onCollapse={() => setActiveSubmenu(null)}
              {...item}
            />
          </motion.li>
        ))}
      </motion.ul>
    </>
  );
};

export default MegaMenuNavigation;
