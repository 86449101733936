import React from "react";
import type {
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from "gatsby";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@/contexts/theme";
import { TranslationsProvider } from "@/contexts/translations";
import Theme from "@/layouts/partials/theme";
import deMessages from "@/locales/de";

type RootProps = WrapPageElementBrowserArgs | WrapPageElementNodeArgs;

const Root: React.FC<RootProps> = ({ element, props }) => {
  const { pageContext } = props;
  const locale = pageContext.locale as string;

  return (
    <TranslationsProvider>
      <IntlProvider
        messages={locale?.includes("de") ? deMessages : {}}
        locale={locale}
        defaultLocale="en"
      >
        <ThemeProvider>
          <Theme />
          {element}
        </ThemeProvider>
      </IntlProvider>
    </TranslationsProvider>
  );
};

export default Root;
