const translations: Translations = {
  toggle_navigation: "Navigation ein-/ausklappen",
  choose_theme_cold: "Kaltes Farbschema wählen",
  choose_theme_warm: "Warmes Farbschema wählen",
  switch_theme: "Farbschema wechseln",
  newsletter_intro:
    "Neuigkeiten aus unseren Zukunftslaboren, Veranstaltungen und mehr.",
  newsletter_disclaimer:
    "Ich willige ein, dass mir von der Geschäftsstelle des Zentrums Klimaforschung Niedersachsen der Newsletter des ZKfN regelmäßig zugesendet wird. Diese Einwilligung ist freiwillig und kann von mir jederzeit widerrufen werden. <link>Datenschutzerklärung</link>",
  newsletter_subscribe: "Newsletter abonnieren",
  newsletter_button: "Abonnieren",
  newsletter_success: "Vielen Dank für die Registrierung.",
  privacy_error: "Bitte akzeptieren Sie die Datenschutzerklärung.",
  email: "E-Mail",
  enter_email: "E-Mail-Adresse eingeben",
  follow_us: "Folgen",
  follow_us_long: "Klima folgen",
  scroll_to_top: "Nach oben",
  explore: "Mehr erfahren",
  blog: "Magazin",
  tender: "Ausschreibung",
  current_tenders: "Aktuelle Ausschreibungen",
  open_tenders: "Offene Ausschreibungen",
  view: "Ansehen",
  date: "{date, date, long}",
  reading_time: "{minutes, number} min. Lesezeit",
  deadline_future: "bis {deadline, date, long}",
  deadline_past: "beendet seit {deadline, date, long}",
  open: "offen",
  contact_person: "Ansprechpartner:in",
  contact_persons: "Ansprechpartner:innen",
  phone: "Telefon",
  mobile: "Mobil",
  back: "Zurück",
  futurelab: "Zukunftslabor",
  futurelabs: "Zukunftslabore",
  more_posts: "Weitere Meldungen",
  all: "Alle",
  not_found: "Nicht gefunden",
  not_found_description:
    "Tut uns leid, die gesuchte Seite konnte leider nicht gefunden werden.",
  goto_homepage: "Zur Startseite",
  stories: "Im Fokus",
  news: "Meldungen",
  dossiers: "Dossiers",
  pagination_label: "Seitennavigation",
  page_number: "Seite {page, number}",
  goto_page: "Seite {page, number} öffnen",
  faq: "Häufig gestellte Fragen",
  more_about_person: "Mehr zur Person",
  expand: "Ausklappen",
  collapse: "Einklappen",
  links: "Links",
  footer_funding:
    "Gefördert mit Mitteln aus zukunft.niedersachsen, ein Förderprogramm des Niedersächsischen Ministeriums für Wissenschaft und Kultur und der VolkswagenStiftung.",
  footer_image_source:
    "Luftaufnahmen aus den Geodaten des Landesamtes für Geoinformation und Landesvermessung Niedersachsen",
  participate: "Teilnehmen",
  participate_intro:
    "Ich möchte an der Veranstaltung {subject} am {date, date, long} teilnehmen.",
  participate_disclaimer:
    "Ich habe die <link>Datenschutzerklärung</link> gelesen und akzeptiere diese.",
  participate_success:
    "Vielen Dank für die Anmeldung. Sie erhalten in Kürze weitere Informationen zum Event.",
  label_name: "Name",
  label_institution: "Einrichtung",
  label_email: "E-Mail",
  label_topic: "Thematische Ausrichtung der Projektideen",
  placeholder_name: "Ihr Name",
  placeholder_institution: "Name der Institution",
  placeholder_email: "beispiel@domain.de",
  placeholder_topic: "Thema eingeben",
};

export default translations;
